<template>
	<CustomTable
		:recent-filter="recentFilters"
		:total-pages="totalPages"
		:total-items="totalItems"
		:label-search="$t('CustomerBillFilters.search')"
		:label-date="$t('CustomerBillFilters.created')"
		:show-export-button="showButtonExport"
		:total-revenue="totalRevenue"
		:show-filter-customers="true"
		:show-filter-price="true"
		:show-total-revenue="true"
		:show-highlight-button="showHighlightMode"
		:show-highlight-mode="!showHighlightMode"
		:is-disabled-highlight="showBtnSaveHighlight"
		:customers="customers"
		table-name="customer-bill"
		@search="search"
		@exportExcel="exportExcel"
		@highlightMode="highlightMode"
		@cancelHighlightMode="cancelHighlightMode"
		@saveHighlightMode="saveHighlightMode"
		@get-customers="getCustomers"
	>
		<template #headers>
			<tr>
				<th
					v-for="(header, index1) in headers"
					:key="`${header.title}-${index1}`"
					scope="col"
					:class="`${header.class}`"
				>
					<template v-if="!header.title">
						<b-form-checkbox
							:id="`checkbox-all`"
							v-model="selectedAll"
							:name="`checkbox-all`"
							class="checkbox"
							@change="selecteAll($event)"
						/>
					</template>
					<template v-else>
						{{ header.title }}
					</template>
				</th>
			</tr>
		</template>
		<template #body>
			<template v-if="customerBills && customerBills.length">
				<tr
					v-for="(item, index2) in customerBills"
					:key="`${item.id}-${index2}`"
					:style="setColorHighlight(item)"
				>
					<td v-if="!showHighlightMode" scope="row" class="table-no text-center">
						<b-form-checkbox
							:id="`checkbox-${index2}`"
							:ref="`checkbox-${item.updateId}`"
							v-model="item.settings.checked"
							:name="`checkbox-${index2}`"
							class="checkbox"
							@change="slectedOrderHighlight($event, item)"
						/>
					</td>
					<td scope="row" class="text-break text-center fit">
						{{ item.updateId }}
					</td>
					<td scope="row" class="text-break text-center fit">
						{{ item.orderId }}
					</td>
					<td scope="row" class="text-break text-center fit">
						{{ item.customerName }}
					</td>
					<td scope="row" class="text-break text-center see-more">
						{{ item.title }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertDateToDateVN(item.deadline) }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertUTCtoDateVN(item.receivedDate) }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertDateToDateVN(item.completedDate) }}
					</td>
					<td scope="row" class="text-break text-center">
						{{ convertAmount(item.amount) }}
					</td>
					<td scope="row" class="text-break text-center fit">
						{{ item.price }}
					</td>
					<td scope="row" class="text-break text-center note">
						<CustomerNote
							:id="item.updateId"
							:index="index2"
							:value="item.note"
							@save="handleNote"
						/>
					</td>
				</tr>
			</template>
			<template v-else>
				<tr class="text-center">
					<td colspan="11" class="fit">
						{{ $t("Table.NoData") }}
					</td>
				</tr>
			</template>
		</template>
	</CustomTable>
</template>

<script>
import { isArray } from "lodash-es"
import { createNamespacedHelpers } from "vuex"
import { COLOR_SCHEMA, PARAMS } from "../../../shared/plugins/constants"
import {
	GET_CUSTOMER_BILL,
	EXPORT_CUSTOMER_BILL,
	HIGHLIGHT_CUSTOMER_BILL,
	GET_CUSTOMER_COMPLETED,
	SAVE_CUSTOMER_NOTE,
} from "../store/action-types"
import { RESET_FILTERS_CUSTOMER_BILL } from "../store/mutation-types"
const { mapState, mapActions, mapMutations, mapGetters } = createNamespacedHelpers("customer-bill")
export default {
	name: "CustomerBillManagement",
	components: {
		CustomerNote: () => import("../components/CustomerNote.vue"),
	},
	data() {
		return {
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			showHighlightMode: true,
			selectedAll: false,
			listColor: [
				{
					name: this.$t("DropDown.White"),
					value: COLOR_SCHEMA.WHITE,
				},
				{
					name: this.$t("DropDown.Yellow"),
					value: COLOR_SCHEMA.YELLOW,
				},
				{
					name: this.$t("DropDown.Green"),
					value: COLOR_SCHEMA.GREEN,
				},
				{
					name: this.$t("DropDown.Red"),
					value: COLOR_SCHEMA.RED,
				},
			],
			colorSelected: {
				name: this.$t("DropDown.White"),
				value: COLOR_SCHEMA.WHITE,
			},
			customerBillsSelected: [],
			customerBillsUnselected: [],
		}
	},
	computed: {
		...mapState(["recentFilters", "totalPages", "totalItems", "totalRevenue", "customers"]),
		...mapGetters(["customerBills", "customerBillSelectedStore"]),
		showButtonExport() {
			if (this.customerBills && this.customerBills.length) {
				return true
			}
			return false
		},
		headers() {
			if (this.showHighlightMode) {
				return [
					{
						title: this.$t("CustomerBillFieldTable.UpdateID"),
						class: "fit text-center",
					},
					{
						title: this.$t("CustomerBillFieldTable.OrderID"),
						class: "fit text-center",
					},
					{
						title: this.$t("CustomerBillFieldTable.CustomerName"),
						class: "text-nowrap text-center fit",
					},
					{
						title: this.$t("CustomerBillFieldTable.Title"),
						class: "text-nowrap title text-center",
					},
					{
						title: this.$t("CustomerBillFieldTable.Deadline"),
						class: "text-nowrap text-center date",
					},
					{
						title: this.$t("CustomerBillFieldTable.ReceivedTime"),
						class: "text-nowrap text-center date",
					},
					{
						title: this.$t("CustomerBillFieldTable.CompleteDate"),
						class: "text-nowrap text-center date",
					},
					{
						title: this.$t("CustomerBillFieldTable.Amount"),
						class: "text-nowrap text-center amount",
					},
					{
						title: this.$t("CustomerBillFieldTable.Price"),
						class: "fit text-center",
					},
					{
						title: this.$t("OrderFieldTable.Note"),
						class: "text-nowrap text-center note",
					},
				]
			}
			return [
				{
					title: "",
					class: "fit-id text-center",
				},
				{
					title: this.$t("CustomerBillFieldTable.UpdateID"),
					class: "fit text-center",
				},
				{
					title: this.$t("CustomerBillFieldTable.OrderID"),
					class: "fit text-center",
				},
				{
					title: this.$t("CustomerBillFieldTable.CustomerName"),
					class: "text-nowrap text-center fit",
				},
				{
					title: this.$t("CustomerBillFieldTable.Title"),
					class: "text-nowrap title text-center",
				},
				{
					title: this.$t("CustomerBillFieldTable.Deadline"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("CustomerBillFieldTable.ReceivedTime"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("CustomerBillFieldTable.CompleteDate"),
					class: "text-nowrap text-center date",
				},
				{
					title: this.$t("CustomerBillFieldTable.Amount"),
					class: "text-nowrap text-center amount",
				},
				{
					title: this.$t("CustomerBillFieldTable.Price"),
					class: "fit text-center",
				},
				{
					title: this.$t("OrderFieldTable.Note"),
					class: "text-nowrap text-center note",
				},
			]
		},
		showBtnSaveHighlight() {
			if (this.customerBillsSelected.length || this.customerBillsUnselected.length) {
				return false
			}
			return true
		},
	},
	watch: {
		customerBillSelectedStore: {
			handler(val) {
				if (val.length) {
					this.customerBillsSelected = [...this.customerBillSelectedStore]
				}
			},
			deep: true,
		},
		customerBills: {
			handler(val) {
				this.handleCheckSelectedAll(val)
			},
			deep: true,
		},
	},
	beforeDestroy() {
		this.RESET_FILTERS_CUSTOMER_BILL()
	},
	methods: {
		...mapActions({
			GET_CUSTOMER_BILL,
			EXPORT_CUSTOMER_BILL,
			HIGHLIGHT_CUSTOMER_BILL,
			GET_CUSTOMER_COMPLETED,
			SAVE_CUSTOMER_NOTE,
		}),
		...mapMutations({ RESET_FILTERS_CUSTOMER_BILL }),
		async search(params) {
			if (params.from) {
				params["completedFrom"] = params.from
				delete params.from
			}

			if (params.to) {
				params["completedTo"] = params.to
				delete params.to
			}
			if (params.customerIds && !isArray(params.customerIds)) {
				params["customerIds"] = [params.customerIds]
			}
			await this.GET_CUSTOMER_BILL(params)
		},
		async exportExcel() {
			let params = {
				...this.recentFilters,
				isExport: true,
			}
			delete params.limit
			delete params.offset

			if (params.from) {
				params["completedFrom"] = params.from
				delete params.from
			}
			if (params.to) {
				params["completedTo"] = params.to
				delete params.to
			}

			await this.EXPORT_CUSTOMER_BILL(params)
		},
		convertAmount(amounts) {
			let amount = ""
			if (!amounts?.length) return null

			amounts.forEach(el => {
				if (!el.amount) {
					if (amount) {
						amount = `${amount}`
					} else {
						amount = +`${amount}`
					}
				} else if (amount) {
					amount = `${amount} + ${el.amount}${el.orderTypeId}`
				} else {
					amount = `${el.amount}${el.orderTypeId}`
				}
			})
			return amount
		},
		async getCustomers() {
			const completedFrom = this.$route.query.from
			const completedTo = this.$route.query.to
			let params = {}

			if (completedFrom) {
				params["completedFrom"] = completedFrom
			}
			if (completedTo) {
				params["completedTo"] = completedTo
			}

			return await this.GET_CUSTOMER_COMPLETED(params)
		},
		highlightMode() {
			this.showHighlightMode = false
		},
		cancelHighlightMode() {
			this.customerBills.forEach(el => {
				if (!el.settings.color) {
					el.settings.checked = false
				} else {
					el.settings.checked = true
				}
			})
			this.handleCheckSelectedAll(this.customerBills)
			this.showHighlightMode = true
		},
		async saveHighlightMode() {
			const params = {
				updateOrderIds: this.customerBillsSelected.map(el => el.updateId),
				unHighlightUpdateIds: this.customerBillsUnselected.map(el => el.updateId),
				settings: {
					color: COLOR_SCHEMA.GRAY,
				},
			}
			await this.HIGHLIGHT_CUSTOMER_BILL(params)
			this.showHighlightMode = true
			this.customerBillsSelected = []
			this.customerBillsUnselected = []
		},
		slectedOrderHighlight(value, customerBill) {
			if (value) {
				this.customerBillsSelected.push(customerBill)
				if (this.customerBills.length === this.customerBillsSelected.length) {
					this.selectedAll = true
				}
				if (customerBill.settings.color) {
					const deleteIndex = this.customerBillsUnselected.findIndex(
						el => el.updateId === customerBill.updateId,
					)
					this.customerBillsUnselected.splice(deleteIndex, 1)
				}
			} else {
				const deleteIndex = this.customerBillsSelected.findIndex(
					el => el.updateId === customerBill.updateId,
				)
				this.customerBillsSelected.splice(deleteIndex, 1)
				if (customerBill.settings.color) {
					this.customerBillsUnselected.push(customerBill)
				}
				this.selectedAll = false
			}
		},
		setColorHighlight(order) {
			if (order.settings?.color) {
				return {
					background: order.settings.color,
				}
			}
			return {
				background: COLOR_SCHEMA.WHITE,
			}
		},
		selecteAll(checked) {
			if (!checked) {
				this.customerBills.forEach((el, index) => {
					const checkbox = document.querySelector(`#checkbox-${index}`)
					checkbox.click()
				})
			} else {
				this.customerBills.forEach((el, index) => {
					const checkbox = document.querySelector(`#checkbox-${index}`)
					if (!checkbox.checked) {
						checkbox.click()
					}
				})
			}
		},
		async handleNote(params) {
			await this.SAVE_CUSTOMER_NOTE(params)
		},
		handleCheckSelectedAll(array) {
			if (!array.length) return

			let isCheckAll = true

			array.forEach(el => {
				if (!el.settings.color) {
					isCheckAll = false
				}
			})

			if (isCheckAll) {
				this.selectedAll = true
			} else {
				this.selectedAll = false
			}
		},
	},
}
</script>
<style lang="scss">
.customer-multiselect {
	.multiselect__tags {
		max-height: 100px;
		overflow-y: auto;
	}
}
</style>
